import axios from "axios";
import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

import router from "./router";
import store from "./store";

axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});

axios.interceptors.request.use(
    (config) => {
        config.headers["Authorization"] = "cAz6cRdAz6aq1Z4rdAz6q1ZebcRdAz6q1Zeb4r";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

createApp(App).use(store).use(router).use(Quasar, quasarUserOptions).mount("#app");
