import script from "./Loader.vue?vue&type=script&setup=true&lang=js"
export * from "./Loader.vue?vue&type=script&setup=true&lang=js"

import "./Loader.vue?vue&type=style&index=0&id=fd3ff44c&lang=scss"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner});
