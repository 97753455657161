<template>
    <loader :isLoading="isLoading" />
    <router-view />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Loader from "./components/Loader";

const store = useStore();

const isLoading = computed(() => store.state.isLoading);
</script>
