import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/representatives/:name",
        name: "representatives",
        component: () => import("../views/representatives/Representatives.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
