import axios from "axios";
import { createStore } from "vuex";

export default createStore({
    state: {
        namespaced: true,
        baseUrl: "/",
        // baseApiUrl: "/api",
        baseApiUrl: "https://forms.sar-el.org/api",
        // baseUrl: "http://127.0.0.1:8000/",
        // baseApiUrl: "http://127.0.0.1:8000/api",

        isLoading: true,
        campaigns: [],
        formName: null,
        thankYouMessage: null,
        showThankYouMessage: false,
        formNeedsAuth: true,
        formUsername: null,
        formPassword: null,
    },
    mutations: {
        SET_IS_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        SET_CAMPAIGNS(state, campaigns) {
            state.campaigns = campaigns;
        },
        SET_FORM_NAME(state, formName) {
            state.formName = formName;
        },
        SET_THANK_YOU_MESSAGE(state, thankYouMessage) {
            state.thankYouMessage = thankYouMessage;
        },
        SET_SHOW_THANK_YOU_MESSAGE(state, showThankYouMessage) {
            state.showThankYouMessage = showThankYouMessage;
        },
        SET_FORM_NEEDS_AUTH(state, formNeedsAuth) {
            state.formNeedsAuth = formNeedsAuth;
        },
        SET_FORM_USERNAME(state, formUsername) {
            state.formUsername = formUsername;
        },
        SET_FORM_PASSWORD(state, formPassword) {
            state.formPassword = formPassword;
        },
    },
    actions: {
        setIsLoading({ commit }, isLoading) {
            commit("SET_IS_LOADING", isLoading);
        },
        setShowThankYouMessage({ commit }, showThankYouMessage) {
            commit("SET_SHOW_THANK_YOU_MESSAGE", showThankYouMessage);
        },
        getCampaigns({ commit, state }, payload) {
            const name = payload.route.params.name && payload.route.params.name !== "" ? payload.route.params.name : null;
            if (name === null) window.location.href = "/not-found";
            axios
                .get(`${state.baseApiUrl}/custom-form/${name}`)
                .then((response) => {
                    if (response && response.data && response.data.data) commit("SET_CAMPAIGNS", response.data.data);
                    if (response && response.data && response.data.formName) commit("SET_FORM_NAME", response.data.formName);
                    if (response && response.data && response.data.thankYouMessage) commit("SET_THANK_YOU_MESSAGE", response.data.thankYouMessage);
                    if (response && response.data && typeof response.data.needsAuth === "boolean") commit("SET_FORM_NEEDS_AUTH", response.data.needsAuth);
                })
                .catch(() => (window.location.href = "/not-found"))
                .finally(() => commit("SET_IS_LOADING", false));
        },
        loginToForm({ commit, dispatch, state }, payload) {
            const name = payload.route.params.name && payload.route.params.name !== "" ? payload.route.params.name : null;
            commit("SET_IS_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/custom-form/${name}/authenticate`, {
                    _u: payload.username,
                    _p: payload.password,
                })
                .then(async (res) => {
                    if (res && res.data && res.data.success) {
                        commit("SET_FORM_NEEDS_AUTH", false);
                        commit("SET_FORM_USERNAME", payload.username);
                        commit("SET_FORM_PASSWORD", payload.password);
                    } else await dispatch("loginWithMessage", { router: { ...payload.router } });
                })
                .catch(async () => {
                    await dispatch("loginWithMessage", { router: { ...payload.router } });
                })
                .finally(() => {
                    commit("SET_IS_LOADING", false);
                });
        },
        sendNewForm({ commit, state }, payload) {
            commit("SET_IS_LOADING", true);
            axios
                .post(`${state.baseApiUrl}/custom-form`, payload.data, {
                    headers: { "Content-Type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) },
                })
                .then((response) => {
                    if (response && response.data && response.data.success) commit("SET_SHOW_THANK_YOU_MESSAGE", true);
                })
                .finally(() => commit("SET_IS_LOADING", false));
            commit("SET_IS_LOADING", false);
        },
        async loginWithMessage({ commit }, payload) {
            commit("SET_IS_LOADING", true);
            await payload.router.push({ params: { message: "Incorrect username or password." } });
            commit("SET_IS_LOADING", false);
        },
    },
});
